import React, { useState, useEffect } from 'react';
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className="fixed bottom-8 right-8 z-[9999]">
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="CTABtn w-fit p-4 text-white bg-primary hover:text-black hover:bg-secondary border-2 border-white flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowUp} className="size-6" />
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;
