import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import "./Header.css";
import PageScrollProgressBar from "react-page-scroll-progress-bar";
import CTAButton from "../CTAButton";
import Parents from "../../assets/header/parent.svg";
import Students from "../../assets/header/student.svg";
import Institute from "../../assets/header/institute.svg";

export default function Header() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);
  const menuRef = useRef<HTMLDivElement>(null);
  const navLinkRef = useRef<HTMLLIElement[]>([]);

  function getActiveLinkIndex() {
    if (location.pathname === "/") {
      return 0;
    } else if (
      location.pathname === "/students" ||
      location.pathname === "/parents" ||
      location.pathname === "/educational-institutes"
    ) {
      return 1;
    } else if (location.pathname === "/careers") {
      return 2;
    } else if (location.pathname === "/blog") {
      return 3;
    } else {
      return -1;
    }
  }

  // const [activeLinkIndex, setActiveLinkIndex] = useState(getActiveLinkIndex());
  const activeLinkIndex = getActiveLinkIndex();

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".close-button")
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const containerRef = useRef(null);

  // nav link indicator slider
  const listRef = useRef<HTMLUListElement | null>(null);
  const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>({
    marginLeft: 0,
    width: 0,
  });

  const handleNavMouseEnter = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    const listRect = listRef.current?.getBoundingClientRect();
    const itemRect = (
      event.currentTarget as HTMLLIElement
    ).getBoundingClientRect();

    const listStart = listRect?.left || 0;
    const itemStart = itemRect.left;
    const itemEnd = itemRect.right;

    const distanceListStartToItemStart = itemStart - listStart;
    const distanceItemEndToItemStart = itemEnd - itemStart;

    setIndicatorStyle({
      marginLeft: `${distanceListStartToItemStart}px`,
      width: `${distanceItemEndToItemStart}px`,
    });
  };

  const setIndicatorToActiveLink = () => {
    if (activeLinkIndex == -1) {
      setIndicatorStyle({
        marginLeft: `${0}px`,
        width: `${0}px`,
      });
      return;
    }

    const linkElement = navLinkRef.current[activeLinkIndex || 0];
    const listElement = listRef.current;

    if (!linkElement || !listElement) return;

    const listRect = listRef.current?.getBoundingClientRect();
    const itemRect = linkElement?.getBoundingClientRect();

    const listStart = listRect?.left || 0;
    const itemStart = itemRect.left;
    const itemEnd = itemRect.right;

    const distanceListStartToItemStart = itemStart - listStart;
    const distanceItemEndToItemStart = itemEnd - itemStart;

    setIndicatorStyle({
      marginLeft: `${distanceListStartToItemStart}px`,
      width: `${distanceItemEndToItemStart}px`,
    });

    console.log("setIndicatorToActiveLink", activeLinkIndex);
  };
  useEffect(() => {
    console.log("useEffect", activeLinkIndex);
    setIndicatorToActiveLink();
  }, [location.pathname]);

  const handleNavMouseLeave = () => {
    setIndicatorToActiveLink();
  };

  return (
    <div ref={containerRef}>
      <PageScrollProgressBar
        container={containerRef.current}
        color="#FFC700"
        bgColor="#f2f2f2"
        height="4px"
      />
      <div
        className={`w-full top-0 z-50 fixed ${isScrolled
          ? "bg-white/[.06] duration-500 ease-in-out shadow-md sticky-header activeMenu"
          : "bg-transparent"
          }`}
      >
        <header
          className={`container mx-auto flex flex-row justify-between items-center py-2 ${isScrolled ? "max-lg:bg-white" : "max-lg:bg-primary"
            }`}
        >
          <Link to="/">
            {isScrolled ? (
              <img
                src="/images/dd-logo.png"
                alt="Company logo"
                className="w-28"
                loading="lazy"
              />
            ) : (
              <img
                src="/images/dd-white-logo.png"
                alt="Company logo"
                className="w-28"
                loading="lazy"
              />
            )}
          </Link>
          <nav
            ref={menuRef}
            className={`navigation ${isOpen ? "block" : "hidden"} lg:block`}
          >
            <ul
              ref={listRef}
              className={`absolute w-full lg:w-fit ${isScrolled ? "max-lg:bg-white" : "max-lg:bg-[#003FBC]"
                } left-0 lg:top-0 top-[4.8rem] lg:relative flex flex-col lg:flex-row justify-between items-center gap-6 p-10 lg:p-0 z-20 ${isOpen ? "overflow-y-auto" : ""
                }`}
            >
              <li
                className={`sm:py-3 lg:py-0 w-fit sm:w-[59px]`}
                ref={(el) => (navLinkRef.current[0] = el!)}
                onMouseEnter={handleNavMouseEnter}
                onMouseLeave={handleNavMouseLeave}
              >
                <CustomLink
                  to="/"
                  isActive={
                    location.pathname === "/" || location.pathname === "/home"
                  }
                  isScrolled={isScrolled}
                  onClick={() => setIsOpen(false)}
                  setIsOpen={setIsOpen}
                >
                  Home
                </CustomLink>
              </li>
              <li
                className={`sm:py-3  lg:py-0 w-fit sm:w-[78px]`}
                ref={(el) => (navLinkRef.current[1] = el!)}
                onMouseEnter={handleNavMouseEnter}
                onMouseLeave={handleNavMouseLeave}
              >
                <CustomLink
                  to="#"
                  isActive={
                    location.pathname === "/parents" ||
                    location.pathname === "/students" ||
                    location.pathname === "/educational-institutes"
                  }
                  isScrolled={isScrolled}
                  onClick={() => setIsOpen(false)}
                  setIsOpen={setIsOpen}
                  dropdownItems={[
                    {
                      title: "Students",
                      icon: Students,
                      link: "/students",
                    },
                    {
                      title: "Parents",
                      icon: Parents,
                      link: "/parents",
                    },
                    {
                      title: "Educational Institutes",
                      icon: Institute,
                      link: "/educational-institutes",
                    },
                  ]}
                >
                  Solutions
                </CustomLink>
              </li>
              <li
                className={`sm:py-3  lg:py-0 w-fit sm:w-[68px]`}
                ref={(el) => (navLinkRef.current[2] = el!)}
                onMouseEnter={handleNavMouseEnter}
                onMouseLeave={handleNavMouseLeave}
              >
                <CustomLink
                  to="/careers"
                  isActive={location.pathname === "/careers"}
                  isScrolled={isScrolled}
                  onClick={() => setIsOpen(false)}
                  setIsOpen={setIsOpen}
                >
                  Careers
                </CustomLink>
              </li>
              <li
                className={`sm:py-3  lg:py-0 w-fit sm:w-[40px]`}
                ref={(el) => (navLinkRef.current[3] = el!)}
                onMouseEnter={handleNavMouseEnter}
                onMouseLeave={handleNavMouseLeave}
              >
                <CustomLink
                  to="/blog"
                  isActive={location.pathname === "/blog"}
                  isScrolled={isScrolled}
                  onClick={() => setIsOpen(false)}
                  setIsOpen={setIsOpen}
                >
                  Blog
                </CustomLink>
              </li>
              <li className="sm:py-3  lg:py-0 block lg:hidden w-fit">
                <CustomLink
                  to="/touch"
                  isActive={location.pathname === "/touch"}
                  isScrolled={isScrolled}
                  setIsOpen={setIsOpen}
                  onClick={() => setIsOpen(false)}
                >
                  Contact
                </CustomLink>
              </li>
            </ul>
            <div className="hidden sm:block relative -translate-y-[0.2rem]">
              {activeLinkIndex !== undefined && (
                <div className="indicator" style={indicatorStyle}></div>
              )}
            </div>
          </nav>
          <div className="lg:hidden p-4">
            <button
              onClick={toggleMenu}
              className="text-gray-600 hover:text-blue-700 focus:outline-none close-button"
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  width={24}
                  height={24}
                  fill={isScrolled ? "#000000" : "#ffffff"}
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={24}
                  height={24}
                  fill={isScrolled ? "#000000" : "#ffffff"}
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
              )}
            </button>
          </div>
          <CTAButton
            text="Contact us"
            link="/touch"
            className="hidden lg:block"
          />
        </header>
      </div>
      <div
        className={`backdrop ${isOpen ? "block" : "hidden"
          } lg:hidden z-[10] bg-[#000000aa] fixed top-0 left-0 h-full w-full`}
      ></div>
    </div>
  );
}

function CustomLink({
  to,
  children,
  isActive,
  isScrolled,
  onClick,
  dropdownItems,
  setIsOpen,
}: {
  to: string;
  children: React.ReactNode;
  isActive: boolean;
  isScrolled: boolean;
  onClick?: () => void;
  dropdownItems?: { title: string; icon: string; link: string }[];
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (dropdownItems) {
      setShowDropdown((prev) => !prev);
      event.preventDefault(); // Prevent the default action
      event.stopPropagation(); // Stop event propagation
    } else {
      if (onClick) onClick();
      if (setIsOpen) setIsOpen(false);
    }
  };

  const handleDropdownItemClick = () => {
    if (setIsOpen) setIsOpen(false); // Close the menu
  };

  const handleMouseEnter = () => {
    if (dropdownItems) {
      setShowDropdown(true);
    }
  };

  const handleMouseLeave = () => {
    if (dropdownItems) {
      setShowDropdown(false);
    }
  };

  return (
    <div className="flex flex-col w-full md:w-auto lg:w-full items-center">
      <Link
        to={to}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`text-lg flex flex-row items-center max-lg:justify-between ${isScrolled ? "text-black" : "text-white"} ${isActive ? "active-link font-semibold text-primary" : "font-medium"}`}
      >
        {children}
      </Link>
      {dropdownItems && showDropdown && (
        <ul
          className="lg:absolute w-fit sm:mt-[1.75rem] md:mt-0 lg:mt-[1.75rem]"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => { setShowDropdown((prev) => !prev) }}
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="mt-4 bg-white shadow-md w-full md:w-auto lg:w-full">
            {dropdownItems.map((item, index) => (
              <li key={index} className="hover:bg-[#ffc8003b]">
                <NavLink
                  to={item.link}
                  className={({ isActive }) =>
                    `${isActive ? "border-b-4 border-secondary bg-[#ffc8003b]" : ""} block px-2 md:px-8 lg:px-2 py-2 md:py-2 flex gap-2 text-sm md:text-base md:whitespace-nowrap`
                  }
                  target="_self"
                  onClick={handleDropdownItemClick}
                >
                  <img src={item.icon} className="w-4 h-4 md:w-6 md:h-6" />
                  <span className="">{item.title}</span>
                </NavLink>
              </li>
            ))}
          </div>
        </ul>
      )}
    </div>

  );
}
