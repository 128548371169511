import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./ServiceList.css";

interface ServiceListProps {
    title: string;
    services: { name: string; url: string }[];
}

const ScrollToTopLink: React.FC<{ to: string; className?: string; children: React.ReactNode; dataHover: string }> = ({ to, className, children, dataHover, ...props }) => {
    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (location.pathname === to) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            event.preventDefault(); // Prevent default link behavior
        }
    };

    return (
        <Link to={to} className={className} onClick={handleClick} {...props} data-hover={dataHover}>
            {children}
        </Link>
    );
};

const ServiceList: React.FC<ServiceListProps> = ({ title, services }) => (
    <>
        <h3 className="text-xl font-bold text-white mb-3.5">{title}</h3>
        <ul className="list-none text-base text-white">
            {services.map((service, index) => (
                <li key={index} className="mb-3.5 last:mb-0">
                    <ScrollToTopLink to={service.url} className="text-whites relative" dataHover={service.name}>
                        {service.name}
                    </ScrollToTopLink>
                </li>
            ))}
        </ul>
    </>
);

export default ServiceList;
