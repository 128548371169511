import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface PageLoadContextType {
  hasLoadingBeenShown: boolean;
  setHasLoadingBeenShown: (value: boolean) => void;
}

// Create a context with the default values and types
const PageLoadContext = createContext<PageLoadContextType>({
  hasLoadingBeenShown: false,
  setHasLoadingBeenShown: () => { },
});

// Define the props for PageLoadProvider explicitly
interface PageLoadProviderProps {
  children: ReactNode;
}

// PageLoadProvider component with explicit props
export const PageLoadProvider: React.FC<PageLoadProviderProps> = ({ children }) => {
  const [hasLoadingBeenShown, setHasLoadingBeenShown] = useState<boolean>(false);

  const value = {
    hasLoadingBeenShown,
    setHasLoadingBeenShown,
  };

  return (
    <PageLoadContext.Provider value={value}>
      {children}
    </PageLoadContext.Provider>
  );
};

// Custom hook to use the PageLoadContext
export const usePageLoad = () => useContext(PageLoadContext);
