import React from "react";
import "./Footer.scss";
import { useState } from "react";
import emailIcon from "../../assets/mail.svg";
import phoneIcon from "../../assets/phone.svg";
import locationIcon from "../../assets/location.svg";
import youtubeIcon from "../../assets/contactUs/youtube.svg";
import facebookIcon from "../../assets/contactUs/facebook.svg";
import twitterIcon from "../../assets/contactUs/twitter.svg";
import linkedinIcon from "../../assets/contactUs/linkedin.svg";
import pinterestIcon from "../../assets/contactUs/pinterest.svg";
import instagramIcon from "../../assets/contactUs/instagram.svg";
import socialIcons from "../../assets/Social-icon.png";
import { keyframes } from "@emotion/react";

// ServiceList import
import ServiceList from "../ServiceList";

interface SocialMediaIconsProps {
  icons: { src: string; alt: string }[];
}
const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({ icons }) => (
  <div className="social mt-8">
    <ul>
      <li>
        <a
          href="https://www.youtube.com/@dreamsdegrees"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center"
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={youtubeIcon}
            alt="Youtube"
            loading="lazy"
          />
        </a>
      </li>
      <li>
        <a
          href="https://in.pinterest.com/dreamsdegrees/"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center"
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={pinterestIcon}
            alt="Youtube"
            loading="lazy"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/DreamsDegrees/"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center"
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={instagramIcon}
            alt="Youtube"
            loading="lazy"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/people/Dreams-And-Degrees/61560469552824/"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center hover:rounded-[50%] "
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={facebookIcon}
            alt="Facebook"
            loading="lazy"
          />
        </a>
      </li>

      <li>
        <a
          href="https://twitter.com/DreamsDegrees"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center hover:rounded-[50%] "
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={twitterIcon}
            alt="Twitter"
            loading="lazy"
          />
        </a>
      </li>

      <li>
        <a
          href="https://www.linkedin.com/company/dreamsanddegrees/"
          target="_blank"
          className="social-media-icons bg-primary w-10 h-10 rounded-lg flex items-center justify-center hover:rounded-[50%]"
        >
          <img
            className="icon-color h-2/3 w-2/3"
            src={linkedinIcon}
            alt="Linkedin"
            loading="lazy"
          />
        </a>
      </li>
    </ul>
  </div>
);

interface ContactInfoProps {
  email: string;
  phone: string;
  address: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ email, phone, address }) => (
  <div className="flex flex-col gap-2 items-start">
    <div className="flex gap-3 items-center">
      <img src={emailIcon} alt="Email icon" className="w-[1.5rem]" />
      <div className="text-white">{email}</div>
    </div>
    <div className="flex gap-3 items-center">
      <img src={phoneIcon} alt="Phone icon" className="w-[1.5rem]" />
      <div className="text-white">{phone}</div>
    </div>
    <div className="flex gap-3 items-center">
      <img src={locationIcon} alt="Address icon" className="w-[1.2rem]" />
      <div className="text-white">{address}</div>
    </div>
  </div>
);

const socialMediaIcons = [
  {
    src: socialIcons,
    alt: "Social media icons",
  },
];

const contactInfo = {
  email: "admin@dreamsanddegrees.com",
  phone: "+91 7997166666",
  address:
    "Plot 38, F301, Road no-1, Kakatiyahills, Madhapur, Shaikpet, Hyderabad, Telangana - 500081",
};

const solutions = [
  { name: "Student Services", url: "/students" },
  { name: "Parent Services", url: "/parents" },
  { name: "Educational Institutions", url: "/educational-institutes" },
];

const resources = [
  { name: "Blogs", url: "/blog" },
  // { name: "FAQs", url: "/faqs" }
];

const company = [
  // { name: "About Us", url: "/about" },
  { name: "Careers", url: "/careers" },
  { name: "Contact Us", url: "/touch" },
];

const customAnimationTop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -80px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [isScrolled, setIsScrolled] = useState(true);

  return (
    <footer className="footer bg-primary">
      <div className="container flex flex-col md:flex-row gap-8 py-8">
        <div className="flex-col md:w-1/2">
          <div className="flex flex-col md:flex-row sm:items-center gap-8">
            <img
              src="/images/dd-white-logo.png"
              alt="Company logo"
              className="w-24 md:w-28"
              loading="lazy"
            />
            <div>
              <ContactInfo {...contactInfo} />
            </div>
          </div>
          <SocialMediaIcons icons={socialMediaIcons} />
        </div>
        <div className="flex items-start md:w-1/2 md:ml-20 lg:ml-0">
          <div className="flex flex-wrap w-full">
            <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
              <ServiceList title="Company" services={company} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
              <ServiceList title="Solutions" services={solutions} />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 mb-8 md:mb-0">
              <ServiceList title="Resources" services={resources} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
