import { Suspense, lazy } from "react";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { PageLoadProvider } from "./pages/home/PageLoadContext";
import { Route, Routes } from "react-router-dom";
import StartFromTop from "./components/StartFromTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
const Home = lazy(() => import("./pages/home/Home"));
const ContactUs = lazy(() => import("./pages/touch-us/touch"));
const Careers = lazy(() => import("./pages/careers/Careers2"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const Students = lazy(() => import("./pages/solutions/Students"));
const Parents = lazy(() => import("./pages/solutions/Parents"));
const Educational = lazy(() => import("./pages/solutions/Educational-Institutes"));


const BlogPost = lazy(() => import("./components/blog-post/BlogPost"));
const BlogPostTwo = lazy(() => import("./components/blog-post/BlogPostTwo"));
const BlogPostThree = lazy(
  () => import("./components/blog-post/BlogPostThree")
);
const BlogPostFour = lazy(() => import("./components/blog-post/BlogPostFour"));
const BlogPostFive = lazy(() => import("./components/blog-post/BlogPostFive"));
const BlogPostSix = lazy(() => import("./components/blog-post/BlogPostSix"));

function App() {
  return (
    <div className="App">
      <PageLoadProvider>
        <Suspense>
          <Header />
          <StartFromTop />
          <ScrollToTopButton />
          <Routes>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="careers" element={<Careers />} />
            <Route path="students" element={<Students />} />
            <Route path="parents" element={<Parents />} />
            <Route path="educational-institutes" element={<Educational />} />
            <Route path="blog" element={<Blog />} />
            <Route path="post/top-10-study-tips" element={<BlogPost />} />
            <Route
              path="post/importance-of-one-on-one"
              element={<BlogPostTwo />}
            />
            <Route
              path="post/navigating-college-application-process"
              element={<BlogPostThree />}
            />
            <Route
              path="post/different-learning-styles"
              element={<BlogPostFour />}
            />
            <Route
              path="post/effective-strategies-for-managing"
              element={<BlogPostFive />}
            />
            <Route
              path="post/role-of-counseling-services-in-academic-success"
              element={<BlogPostSix />}
            />
            <Route path="touch" element={<ContactUs />} />
          </Routes>
          <Footer />
        </Suspense>
      </PageLoadProvider>

    </div>
  );
}

export default App;
